<template>

  <div class="row">
    <div class="col-12 card">
      <div class="row justify-content-space-between top-24">
        <h4 class="col-3 card-title">Добавить пользователя</h4>
        <div class="col-9 align-self-end right">
          <router-link to="/users"><button class="col-3 btn-secondary">Отменить</button></router-link>
          <button class="col-3 btn-primary"><span class="icon-Plus"></span>Сохранить</button>
        </div>
      </div>
      <div class="row">
        <div class="col-12 top-24">
          <div class="text-field">
            <label class="text-field__label" for="search">Логин</label>
            <div class="text-field__icon">
              <input class="text-field__input" type="search" name="search" id="search" placeholder="Введите логин" value="">
              <div class="text-field__message">Будет отображаться в общем списке пользователей</div>
            </div>
          </div>
        </div>
        <div class="col-12 top-24">
          <div class="text-field">
            <label class="text-field__label" for="search">ФИО</label>
            <div class="text-field__icon">
              <input class="text-field__input" type="search" name="search" id="search" placeholder="Введите ФИО" value="">
            </div>
          </div>
        </div>
        <div class="col-7 top-24">
          <div class="text-field">
            <label class="text-field__label" for="search">Пароль</label>
            <div class="text-field__icon">
              <input class="text-field__input" type="password" name="password" id="password" placeholder="Введите пароль" value="">
            </div>
          </div>
        </div>
        <div class="col-7 top-24">
          <div class="text-field">
            <label class="text-field__label" for="search">Пароль ещё раз</label>
            <div class="text-field__icon">
              <input class="text-field__input" type="password" name="password" id="password" placeholder="Введите пароль повторно" value="">
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// import TableView from "@/components/Analytics/ReportsCreate/TableView.vue";
// import ChartView from '@/components/Analytics/ReportsCreate/ChartView.vue';

export default {
  // components: { TableView, ChartView },
  data() {
    return {
      type: "table"
    }
  },
  methods:{
    onChange:function(event){
      this.type = event.target.value;
    }
  }
};
</script>
